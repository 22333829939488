.desktop {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-family: 'Walkway Bold', sans-serif;
  flex-grow: 1;
}

@font-face {
  font-family: 'Walkway Bold';
  src: url('./fonts/Walkway-Bold.ttf') format('truetype'); 
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'Walkway Bold', sans-serif;
}

.desktop .div {
  background-image: url("./images/whiteBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100vw;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.desktop .overlap-group {
  height: 664px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
}

.desktop .imagen-fondo {
  height: 378px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100vw;
}

.desktop .text-wrapper {
  color: #d5c25d;
  font-family: 'Walkway Bold', sans-serif;
  font-size: 46px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 130px;
  width: 500px;
}


.desktop .button {
  all: unset;
  align-items: center;
  color: white;
  background-color: black;
  border: 2px solid;
  border-color: #e3e570;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 54px;
  justify-content: center;
  padding: 12px 24px;
  /*position: absolute;
  top: 243px;*/
  margin-top: 50px;
  width: 314px;
}

.desktop .buttonSec {
  
}

.desktop .button-2 {
  all: unset;
  box-sizing: border-box;
  color: #ccd787;
  font-family: 'Walkway Bold', sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.desktop .proveedor {
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  position: absolute;
  top: 115px;
}

.desktop .rectangle {
  background-color: #d5c25d;
  box-shadow: 0px 4px 4px #00000040;
  height: 600px;
  position: relative;
  width: 500px;
}

.desktop .imagen {
  height: 500px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 500px;
}

.desktop .titulo-experiencia {
  color: black;
  font-family: 'Walkway Bold', sans-serif;
  font-weight: 300;
  font-size: 34px;
  left: 53px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 510px;
  width: 414px;
}

.desktop .informacion-basica {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  top: 424px;
}

.desktop .frame {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 60px;
  padding: 10px 0px;
  position: relative;
  width: 420px;
}

.desktop .text-wrapper-2 {
  align-self: stretch;
  color: #000000;
  font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  width: 420px;
}

.desktop .pepicons-pencil {
  height: 44px;
  left: 126px;
  position: absolute;
  top: 424px;
  width: 44px;
}

.desktop .octicon-person {
  height: 34px;
  left: 126px;
  position: absolute;
  top: 484px;
  width: 34px;
  display: none;
}

.desktop .formkit-time {
  height: 34px;
  left: 126px;
  position: absolute;
  top: 519px;
  width: 34px;
  
}

.desktop .codicon-settings {
  height: 34px;
  left: 126px;
  position: absolute;
  top: 614px;
  width: 34px;
}

.desktop .texto-experiencia {
  color: #000000;
  font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: justify;
  width: 530px;
}

.desktop .overlap {
  height: 462px;
  position: absolute;
  width: 460px;
}

.desktop .imagen-SE {
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 42px;
  
}

.desktop .rectangle-2 {
  border: 4px solid;
  border-color: #000000;
  height: 420px;
  left: 40px;
  position: absolute;
  top: 0;
  width: 420px;
}

.desktop .p {
  color: #000000;
  font-family: 'Walkway Bold', sans-serif;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 38px;
  position: absolute;
  text-align: center;
  top: 869px;
  width: 420px;
}

.fondo-p {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
  background-color: grey;
  background-image: url("./images/whiteBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vmax;
}

.textoInformacion {
  color:whitesmoke;
}

@media (max-width: 1430px){
  .desktop .proveedor {
    display: none;
  }
  .desktop .rectangle-2{
    display: none;
  }
  .desktop .texto-experiencia {
    top: 1205px; /*1205*/
    left: 10vw;
    right: 10vw;
    width: 80vw;
  }
  .desktop .imagen-SE{
    height: 320px;
    width: 300px;
  }
  .desktop .p {
    display: none;
  }
  .desktop .overlap {
    left: 10vw;
    top: 740px;
  }
  .desktop .div{
    height: 2000px;
  }

  .desktop .buttonSec {
    display: none;
  }
}

@media (min-width: 1431px){
  .desktop .buttonSec{
    display: flex;
    all: unset;
    align-items: center;
    color: white;
    background-color: black;
    border: 2px solid;
    border-color: #e3e570;
    box-sizing: border-box;
    gap: 10px;
    height: 54px;
    justify-content: center;
    padding: 12px 24px;
    width: 314px;
    margin-left: 50px;
  }
  .desktop .div{
    height: 1600px;
  }

  .desktop .texto-experiencia {
    top: 840px; /*997*/
    right: 15vw;
    
  }
  .desktop .p {
    right: 15vw;
  }
  .desktop .overlap {
    top: 838px;
    left: 15vw;
  }
  .desktop .imagen-SE{
    height: 420px;
  }
  .desktop .imagen-SE{
    width: 420px;
  }
}


@media (max-width: 1500px){
  .fondo-p .horizontal-p {
    display: none;
  }
  .desktop .text-wrapper {
    left: 10vw;
  }
  .desktop .button {
    left: 10vw;
  }
  .desktop .informacion-basica {
    left: 14vw;
  }
  .desktop .pepicons-pencil {
    left: 6vw;
  }
  .desktop .octicon-person {
    left: 6vw;
  }
  .desktop .formkit-time {
    left: 6vw;
  }
  .desktop .codicon-settings {
    left: 6vw;
  }

}

@media (min-width: 1500px){
  .fondo-p .form-p {
    left: 400px;
  }
  .desktop .text-wrapper {
    left: 15vw;
  }
  .desktop .button {
    left: 15vw;
  }
  .desktop .proveedor {
    right: 15vw;
  }
  .desktop .informacion-basica {
    left: 18vw;
  }
  .desktop .pepicons-pencil {
    left: 14vw;
  }
  
  .desktop .octicon-person {
    left: 14vw;
  }
  
  .desktop .formkit-time {
    left: 14vw;
  }
  
  .desktop .codicon-settings {
    left: 14vw;
  }
}


@media (max-width: 1000px){
  .fondo-p .se-parte-de {
    display: none;
  }
  
}

.fondo-p .horizontal-p {
  align-items: center;
  background-color: #d5c25dcc;
  display: flex;
  gap: 10px;
  height: 337px;
  left: 70px;
  padding: 10px 50px;
  position: absolute;
  top: 158px;
  width: 80vw;
}

.fondo-p .se-parte-de {
  color: #ffffff;
  font-family: 'Walkway Bold', sans-serif;
  font-size: 36px;
  font-weight: 400;
  height: 260px;
  letter-spacing: 0;
  line-height: 42px;
  position: relative;
  width: 230px;
}



.fondo-p .form-p {
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  position: absolute;
  top: 70px;
  background-image: url("./images/imagen-fondo-proveedor.png");
}

.text-uppercase {
  color: #d5c25dcc;
  font-family: 'Walkway Bold', sans-serif;
}